import React from 'react'
import { graphql } from 'gatsby'


import { Grid, GridItem, SimpleGrid} from '@chakra-ui/react'



import WfVid from '../../video/wireframe-vid.mp4'

import { PhoneCard } from '../../components/cards/PhoneCard'

import ProjectHeader from '../../components/Header/ProjectHeader'
import { mypx, col2, xgap  } from '../../constants/spacing'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import SilentPlayer from '../../components/video/SilentPlayer'
import Vpaphone from '../../video/vpaphone.mp4'
import SEO from '../../components/SEO'
import ProjectArrows, { ProjectLinkers } from '../../components/Header/ProjectArrows'
const preventClick = (e) => e.preventDefault()

const Vpa = ({ data, location }) => {
  const currentPath = location.pathname ? location.pathname : ''
  const dat = data.allWpProject.edges[0].node.hollandProjects
  const DesktopVideo = dat.headerVideoDesktop.localFile.publicURL
  const MobileVideo = dat.headerVideoMobile.localFile.publicURL

  let image1;
  let image2;
  let image3;
  let image4;
  let image5;
  let image6;
  let image7;
  let image8;
  let image9;
  let image10;
  let image11;
  let image12;
  
  if(dat.image1) {
    image1 = getImage(dat.image1.localFile)
  } 
  if(dat.image2) {
     image2 = getImage(dat.image2.localFile)
  } 
  if(dat.image3) {
     image3 = getImage(dat.image3.localFile)
  } 
  if(dat.image4) {
     image4 = getImage(dat.image4.localFile)
  } 
  if(dat.image5) {
     image5 = getImage(dat.image5.localFile)
  } 
  if(dat.image6) {
     image6 = getImage(dat.image6.localFile)
  } 
  if(dat.image7) {
     image7 = getImage(dat.image7.localFile)
  } 
  if(dat.image8) {
     image8 = getImage(dat.image8.localFile)
  } 
  if(dat.image9) {
     image9 = getImage(dat.image9.localFile)
  } 
  if(dat.image10) {
     image10 = getImage(dat.image10.localFile)
  } 
  if(dat.image11) {
     image11 = getImage(dat.image11.localFile)
  } 
  if(dat.image12) {
     image12 = getImage(dat.image12.localFile)
  } 

  // const DesktopVideo = dat.headerVideoDesktop.localFile.relativeDirectory

    return (
        <>
        <SEO title={dat.projecttitle} description={dat.projectdescription} image={image1} url="https://hollandhames.com/project/vpa"/>
  <ProjectHeader desktopVid={DesktopVideo} mobileVid={MobileVideo} description={dat.projectdescription} title={dat.projecttitle} />
      <SimpleGrid px={mypx} pb={xgap} gap={xgap} columns={1}>
        <SimpleGrid columns={col2} gap={xgap}>
          <GatsbyImage onContextMenu={preventClick} image={image1} alt={dat.image1.altText} />
          <GatsbyImage onContextMenu={preventClick} image={image2} alt={dat.image2.altText} />
        </SimpleGrid>
        <SimpleGrid gap={xgap}>
          {/* <PhoneCard /> */}
          <SilentPlayer url={Vpaphone} />
        </SimpleGrid>
        <SimpleGrid gap={xgap}>
          <GatsbyImage onContextMenu={preventClick} image={image5} alt={dat.image5.altText} />
        </SimpleGrid>
        <Grid gap={xgap} templateRows="(2 1fr)" templateColumns={["(1, 1fr)",null,null,null,"(3, 1fr)"]}>
          <GridItem colStart={1} colEnd={1} rowStart={1} rowEnd={[1,null,null,null,3]}><GatsbyImage onContextMenu={preventClick} image={image6} alt={dat.image6.altText} /></GridItem>
         <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={[2,null,null,null,2]} rowEnd={[2,null,null,null,3]}><GatsbyImage onContextMenu={preventClick} image={image7} alt={dat.image7.altText} /></GridItem>
        </Grid>
        <Grid gap={xgap} templateRows="(2 1fr)" templateColumns={["(1, 1fr)",null,null,null,"(3, 1fr)"]}>
          <GridItem colStart={1} colEnd={1} rowStart={1} rowEnd={[1,null,null,null,3]}><GatsbyImage onContextMenu={preventClick} image={image8} alt={dat.image8.altText} /></GridItem>
         <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={[2,null,null,null,1]} rowEnd={[2,null,null,null,3]}><GatsbyImage onContextMenu={preventClick} image={image9} alt={dat.image9.altText} /></GridItem>
        </Grid>
        <SimpleGrid gap={xgap}>
          <SilentPlayer url={WfVid} />
        </SimpleGrid>
        <Grid justify="center" align="center" templateRows="(1 1fr)" templateColumns={["(1, 1fr)",null,null,null,"(7, 1fr)"]}>
          <GridItem colStart={1} colEnd={2}></GridItem>
          <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,6]}>
          <GatsbyImage onContextMenu={preventClick} image={image10} alt={dat.image10.altText} />
          </GridItem>
          <GridItem colStart={[1,null,null,null,5]} colEnd={[1,null,null,null,7]}></GridItem>
        </Grid>

      </SimpleGrid>
      <ProjectLinkers prevTitle='Wilson Associates' prevUrl='/project/wilson' nextTitle='Hellofresh Packaging' nextUrl='/project/hellofresh-packaging' />

</>
    )
}

export default Vpa

export const query = graphql`
  {
    allWpProject(filter: {slug: {eq: "vpa"}}) {
      edges {
        node {
          title
          hollandProjects {
            projecttitle
            projectdescription
            projectcategories
            image1 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image2 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image3 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image4 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image5 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image6 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image7 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image8 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image9{
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image10 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, quality: 100, layout:FULL_WIDTH)
                }
              }
            }
            headerVideoDesktop {
              localFile {
                publicURL
              }
            }
            headerVideoMobile {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`